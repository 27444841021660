<template>
  <div></div>
  <!-- <v-carousel
    height="auto"
    cycle
    hide-delimiters
    :show-arrows="true"
    class="custom-carousel"
  >
    <v-carousel-item v-for="(car) in cars" :key="car.id">
      <v-responsive :aspect-ratio="16/9">
        <v-img
          :src="require(`@/${car.path}`)"
          :lazy-src="require(`@/${car.placeholder}`)"
          class="carousel-image"
        />
      </v-responsive>
    </v-carousel-item>
  </v-carousel> -->
</template>

<script>
export default {
  data() {
    return {
      cars: [
        { id: 108, path: 'assets/images/home/new/img8.jpg', placeholder: 'assets/images/home/new/img8_placeholder.jpg' },
        { id: 101, path: 'assets/images/home/new/img1.jpg', placeholder: 'assets/images/home/new/img1_placeholder.jpg' },
        { id: 103, path: 'assets/images/home/new/img3.jpg', placeholder: 'assets/images/home/new/img3_placeholder.jpg' },
        { id: 104, path: 'assets/images/home/new/img4.jpg', placeholder: 'assets/images/home/new/img4_placeholder.jpg' },
        { id: 105, path: 'assets/images/home/new/img5.jpg', placeholder: 'assets/images/home/new/img5_placeholder.jpg' },
        { id: 106, path: 'assets/images/home/new/img6.jpg', placeholder: 'assets/images/home/new/img6_placeholder.jpg' },
        { id: 107, path: 'assets/images/home/new/img7.jpg', placeholder: 'assets/images/home/new/img7_placeholder.jpg' },
      ],
    };
  },
};
</script>

<style scoped>
.custom-carousel {
  height: auto;
  max-height: fit-content;
  background-color: #686464 !important; /* Background color for better contrast */
}

.carousel-image {
  width: 100%;
  height: auto; 
  object-fit: cover;
}
</style>
