import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'show-event-view',
    component: () => import('../views/show-events/show-events.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/add-event',
    name: 'add-event-view',
    component: () => import('../views/add-event/add-event.vue')
  },
  {
    path: '/events',
    name: 'show-event-view',
    component: () => import('../views/show-events/show-events.vue')
  },
  {
    path: '/event-detail',
    name: 'event-detail',
    component: () => import('../views/event-detail/event-detail.vue')
  },
  {
    path: '/settings',
    name: 'settings-view',
    component: () => import('../views/settings/settings-view.vue')
  },
  {
    path: '/host-dashboard',
    name: 'host-dashboard-view',
    component: () => import('../views/host-dashboard/host-dashboard-view.vue')
  },
  {
    path: '/contact',
    name: 'contact-us',
    component: () => import('../views/contact/contact-us.vue')
  },
  {
    path: '/gallery',
    name: 'gallery-view',
    component: () => import('../views/gallery/gallery-view.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy-view',
    component: () => import('../views/privacy-term/privacy-policy.vue')
  },
  {
    path: '/terms-of-use',
    name: 'terms-conditions-view',
    component: () => import('../views/privacy-term/terms-condition.vue')
  },
  {
    path: '/user-management',
    name: 'user-management-view',
    component: () => import('../views/user-management/user-management-view.vue')
  },
  {
    path: '/advertisement/upload',
    name: 'upload-ads-view',
    component: () => import('../views/advertisement/upload-ads.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
