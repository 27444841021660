<template>
  <v-app>
    <header-comp />
    <!-- <v-main :class="['main-content', backgroundClass]"> -->
    <v-main class="main-content bg-image">
      <router-view @zip-code-changed="handleZipCodeChange" />
    </v-main>
    <footer-comp :zip="zipCode" />
  </v-app>
</template>

<script>
import HeaderComp from './components/header-comp';
import FooterComp from './components/footer-comp';
export default {
  name: 'App',

  data() {
    return {
      zipCode: localStorage.getItem('zip') || '', // Track the zip code in App.vue
    };
  },
  components: {
    HeaderComp,
    FooterComp,
  },
  // computed: {
  //   backgroundClass() {
  //     // Check if the current route is "Home" and return the appropriate class
  //     return this.$route.name === 'home' ? 'bg-grey' : 'bg-image';
  //   },
  // },
  methods: {
    handleZipCodeChange(newZipCode) {
      // Update the zip code in local storage and component data
      this.zipCode = newZipCode;
    },
  },
};
</script>

<style scoped>
  .main-content {
    min-height: 100vh; /* Ensure the main content area stretches to full height */
    display: flex;
    flex-direction: column;
  }
  /* .bg-grey {
    background-color: #b0b0b0;
    background-image: none;
  } */
  </style>
