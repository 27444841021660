// store/index.js
import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

Vue.use(Vuex);

const state = {
  isLoggedIn: !!localStorage.getItem('token'), // I want to call an API here
  userEmail: localStorage.getItem('email'),
  userZipCode: localStorage.getItem('zip') || '', // Add zip code to state
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
});
