<template>
  <div>
    <home-page />
  </div>
</template>

<script>
 import HomePage from '../components/pages/home-page-comp';

  export default {
    name: 'Home-vue',
    metaInfo: {
      title: 'Car Shows | Show Us Shows',
      meta: [
        { name: 'description', content: 'Find and subscribe to the best car shows in your area. Get notified of upcoming events by email or SMS.' },
        { name: 'keywords', content: 'car shows, vehicle events, upcoming car shows events' }
      ]
    },
    components: {
      HomePage
    },
  }
</script>
<!-- <style scoped>
  .homeContainer{
    height: auto;
    background-color: #686464 !important;
  }
</style> -->
